export const initialState = {
    isReloading: false,
    loading: false,
    error: null
};

export function setReloading(state, action) {
    return { ...state, isReloading: action.payload };
}

export function incrementLoading(state) {
    return { ...state, loading: true };
}

export function decrementLoading(state) {
    return { ...state, loading: false };
}

export function updateLoading(state, action) {
    return { ...state, loading: action.payload };
}

export function addErrorMessage(state, action) {
    return { ...state, error: action.payload, loading: false };
}
