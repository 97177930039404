import * as types from "../actions/types";
import { updateLoading, addErrorMessage } from "./commonReducer";

const INITIAL_STATE = {
  loading: false,
  error: "",
  type: "",
  violationsData: {},
  violationData: {},
  paymentResponse: {},
  successContact: false,
  violationDetails: {},
  removalInstruction: {},
  firmwareVersion: {},
  authorizeDotNet: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CHANGE_LOADING:
      return updateLoading(state, action);
    case types.SET_ERROR:
      return addErrorMessage(state, action);
    case types.CLEAR_ERROR:
      return addErrorMessage(state, action);
    case types.GET_ALL_VIOLATIONS_BY_PLATE_NUMBER_SUCCESS:
      return {
        ...state,
        violationsData: action.payload,
      };
    case types.GET_UNLOCK_CODE_BY_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentResponse: action.payload,
      };
    case types.SUBMIT_CONTACT_US_SUCCESS:
      return {
        ...state,
        successContact: true,
      };
    case types.GET_VIOLATION_DETAILS_SUCCESS:
      return {
        ...state,
        violationDetails: action.payload.response,
      };
    case types.GET_REMOVAL_INSTRUCTION_SUCCESS:
      return {
        ...state,
        removalInstruction: action.payload.response,
      };
    case types.GET_FIRMWARE_SUCCESS:
      return {
        ...state,
        firmwareVersion: action.payload,
      };
    case types.POST_PAYMENT_AUTHORIZEDOTNET_SUCCESS:
      return {
        ...state,
        authorizeDotNet: action.payload,
      };
    default:
      return state;
  }
}
