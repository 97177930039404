import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {routes} from "../routings";

export default class PageNotFound extends Component {
    render() {
        return (
            <div>
                <section className="main_section wrong_part not_found">
                    <div className="container">
                        <div className="row homie">
                            <div className="col-md-12">
                                <div className="left_part">
                                    <h1 className="some">404 | Page Not Found</h1>
                                    <h1 className="some exclamation">!</h1>
                                    <div className="bottom_buttons error_button">
                                        <Link to={routes.home.path} className="yellow_btn">Go to Home</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}