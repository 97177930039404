export const REACT_APP_API_URL =
  "https://globalparkingservices.com/adminmanager/api/";


// Production
// export const REACT_APP_API_URL = "https://globalparkingservices.com/adminmanager/api/";

// export const REACT_APP_API_URL = "http://34.193.221.44/api/";
// export const REACT_APP_API_URL = "http://34.193.221.44/adminmanager/api/";
// export const REACT_APP_API_URL = "http://34.195.105.216/adminmanager/api/";
// export const REACT_APP_API_URL = "https://globalparkingservices.com/adminmanager/api/";


export const REACT_APP_API_KEY = "YzBjOGQ5NGY5NDViOGI1NDkzMDdhYzk4";
