import { upperFirst } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ApiLoader from "../common/loader";
import {getViolationDetails} from "../redux/actions/violationActions";
import { Link } from 'react-router-dom';
import {routes} from '../routings'

function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString('en-US', options);
}

class PastViolations extends Component {
    constructor(props){
        super(props);
        this.state={
            showDetails: false
        }
    }

    componentDidMount(){
        if (this.props.location.state) {
            console.log(this.props.location.state);
        } else {
            window.location.href = `${routes.home.path}`;
        }
    }

    diff_minutes=(dt2, dt1)=>{
        dt1 = new Date(dt1.replace(/-/g,"/"));
        dt2 = new Date(dt2.replace(/-/g,"/"));
        var diff =(dt2.getTime() - dt1.getTime()) / 1000;
        console.log("diff",diff);
        diff /= 60;
        return Math.abs(Math.round(diff));    
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(Object.keys(nextProps.violationDetails).length > 0){
            return{
                showDetails: true
            }
        }
        return null;
    }

    render() {
        const {loading, violationDetails} = this.props;
        const {showDetails} = this.state;
        let violations={response:[]};
        if (this.props.location.state) {
            violations= this.props.location.state.violationsData;
        } else {
            window.location.href = `${routes.home.path}`;
        }
        if(violations !== undefined && violations !== null){
            if(Object.keys(violations).length == 0 ){
                window.location.href = `${routes.home.path}`;
            }
        } else {
            window.location.href = `${routes.home.path}`;
        }
        return (
            <section className="main_section wrong_part wrong_part_past">
                {showDetails && <Redirect
                        to={{
                            pathname: `${routes.violationDetails.path}`,
                            state: {
                                violationDetails: violationDetails,
                                violationsData: (Object.keys(this.props.violationsData).length > 0) ? this.props.violationsData : this.props.location.state.violationsData
                            }
                        }}
                    />}
                {loading && <ApiLoader />}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="left_part">
                                <h1>Past Violation Details</h1>
                            </div>
                            <div className="row">
                                {violations.response.length > 0 && 
                                    violations.response.map((violation, key)=>{
                                        const minutes = this.diff_minutes(violation.txnDateTime, violation.curDateTime);
                                return (<div className="col-md-4 col-sm-6" key={key}>
                                    <div className="past_img" style={{cursor: 'pointer'}} onClick={()=>this.props.getViolationDetails(violation.license_plate_number, violation.id)}>
                                    <img src={violation?.images?.length > 0 ? violation.images[0] !== '' ? violation.images[0] : "assets/images/w-img1.png"  : "assets/images/w-img1.png"} alt="GPS" />
                                    {/* <img src={violation.images ? violation.images.length > 0 ? violation.images[0] : "assets/images/w-img1.png":"assets/images/w-img1.png"} alt="GPS" /> */}
                                    </div>
                                    <div className="parked">
                                        <p>{upperFirst(violation.reason)}</p>
                                        <h5> <i className="far fa-clock"></i> {minutes > 30 ? formatDate(violation.date) : minutes+" minutes ago"} </h5>
                                    </div>
                                </div>)
                                    })}                                
                            </div>
                            <div className="bottom_buttons code_button">
                                    <a href={routes.home.path} className="default">Back</a>
                                    {/* <Link to="/ab/gps/web-stage" className="default">Back</Link> */}
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    violationDetails: state.violation.violationDetails,
    violationsData: state.violation.violationsData,
    loading: state.violation.loading
});

const dispatchers = {
    getViolationDetails
};

export default connect(mapStateToProps, dispatchers)(PastViolations);