import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getViolatiosByPlateNumber,
  getAPIToken,
} from "../redux/actions/violationActions";
import ApiLoader from "../common/loader";
import { Link } from "react-router-dom";
import { routes } from "../routings";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plateNumber: "",
      sendTo: "",
    };
  }

  componentDidMount() {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    // Extract the 'vin' parameter from the URL
    const vin = urlParams.get("vin");

    this.setState({ sendTo: "", plateNumber: vin ? vin : "" });
    this.props.getAPIToken();

    if (vin) {
      this.searchSubmitFromUrl(vin);
    }
  }

  searchSubmitFromUrl = (vin) => {
    this.props.getViolatiosByPlateNumber(vin);
  };

  searchSubmit = (event) => {
    event.preventDefault();
    this.props.getViolatiosByPlateNumber(this.state.plateNumber);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.violationsData.unpaid) {
      return {
        sendTo: "unpaid",
      };
    } else if (nextProps.violationsData.unpaid === false) {
      return {
        sendTo: "listing",
      };
    } else {
      return {
        sendTo: "",
      };
    }
  }

  render() {
    const { plateNumber, sendTo } = this.state;
    const { loading } = this.props;
    return (
      <div>
        {loading && <ApiLoader />}
        {sendTo === "unpaid" ? (
          <Redirect
            to={{
              pathname: `${routes.unpaidViolation.path}`,
              state: {
                plateNumber: plateNumber,
                violationsData: this.props.violationsData,
              },
            }}
          />
        ) : sendTo === "listing" ? (
          <Redirect
            to={{
              pathname: `${routes.pastViolations.path}`,
              state: {
                plateNumber: plateNumber,
                violationsData: this.props.violationsData,
              },
            }}
          />
        ) : (
          ""
        )}
        <section className="main_section banner wrong_part">
          <div className="container-fluid">
            <div className="row homie">
              <div className="col-md-5"></div>
              <div className="col-md-7">
                <div className="left_part part_left">
                  <h1 className="some">Vehicle immobilized?</h1>
                  <h1 className="some down"> Get the vehicle released.</h1>
                  <div className="bottom_buttons plate_button">
                    <form onSubmit={this.searchSubmit}>
                      <input
                        type="text"
                        name="plateNumber"
                        value={plateNumber}
                        onChange={(e) =>
                          this.setState({ plateNumber: e.target.value })
                        }
                        placeholder="Plate Number or last 6 of VIN"
                      />
                      <button
                        type="submit"
                        disabled={plateNumber === "" ? true : false}
                        className="yellow_btn"
                      >
                        Search
                      </button>
                    </form>
                  </div>
                  <div className="uncess_div">
                    <p className="unsucces">
                      You have a choice to remove the vehicle immobilization
                      device yourself for a discounted rate of $99.99.{" "}
                    </p>
                    <p className="unsucces">
                      To have the vehicle immobilization device removed by a
                      parking technician, the fee is a $120.00. For this
                      service, please send an email to{" "}
                      <a href="mailto:parking@gpsvcs.com">parking@gpsvcs.com</a>{" "}
                      and our team will respond promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  violationsData: state.violation.violationsData,
  loading: state.violation.loading,
});

const dispatchers = {
  getViolatiosByPlateNumber,
  getAPIToken,
};

export default connect(mapStateToProps, dispatchers)(HomeComponent);
