import axios from 'axios';
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import ApiLoader from '../common/loader';



export default class PaypalPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: true,
            reRender: false
        }
        this.onApprove = this.onApprove.bind(this);
    }

    rerender = () => {
        console.log("paypal rerender call")
        this.setState({ reRender: true });
        setTimeout(() => {
            this.setState({ reRender: false })
        }, 100);
    }

    createOrder=(data, actions)=> {
        console.log("paypal create order")
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: this.props.amount,
                        currency: "USD"
                    },
                },
            ],
        });
    }

    sendData = (orderId, details) => {
        // Testing For Phase 1 Credentails
        // const PAYPAL_CLIENT ='ATmyk8JFPyzA4-fTASAjtXZwO5ETUlw6RcJIRiT8Am83RJ45LEvTeui2YB--c8pOG20uPbTzWlWRzZMF'
        // const PAYPAL_SECRET = 'EE2ljHjMlx-Av_iMfFs2GlUbN_lbsjSGkEKCRq55UhWdFmsea0SfDRuhTFirp1_H63-sqjcGDeaV17Sc';
        // const PAYPAL_OAUTH_API = "https://api-m.sandbox.paypal.com/v1/oauth2/token/";
        // const PAYPAL_ORDER_API =  "https://api-m.sandbox.paypal.com/v1/checkout/orders/";
        
        // Live Credentials
        const PAYPAL_CLIENT = 'AWB8I7RE2zo0PB6o4SjIDEQaKDsT_sPDL3jwYXE9srpGtaTwSVSOPlPzl0FWwDXVdBBZ_zHnZb1T9ObH';
        const PAYPAL_SECRET = 'ENtByNccSxhMJkVlTqlWCmaz4vXipwds1tNiFDtAO-AeDOR3j7uZYRw0nEa-lQJbqvjcLvIDFBEDNU6c';
        const PAYPAL_OAUTH_API = "https://api-m.paypal.com/v1/oauth2/token/";
        const PAYPAL_ORDER_API = 'https://api-m.paypal.com/v1/checkout/orders/';

        let access_token = "";

        // 1c. Get an access token from the PayPal API
        const basicAuth = `${PAYPAL_CLIENT}:${PAYPAL_SECRET}`;
        const token = axios.post(PAYPAL_OAUTH_API, `grant_type=client_credentials`,
        {
            headers: {
            Accept: `application/json`,
            Authorization: `Basic ${btoa(basicAuth)}`,
            },
        }
        ).then(Response=>{
            access_token = Response.data.access_token
            axios.get(PAYPAL_ORDER_API+orderId,
                {
                    headers: {
                        Accept: `application/json`,
                        Authorization: `Bearer ${Response.data.access_token}`
                    },
                }
                ).then(Response=>{
                    this.setState({loading: false});
                    this.props.onSuccess(Response.data.purchase_units[0].payments.captures[0].id, details);
                });
        }).catch(error=>{
            access_token = "";
        });
    }

    onApprove = (data, actions) => {
        this.setState({ loading: true });
        actions.order.capture().then(details => {
            this.sendData(details.id, details);
        })
    }

    setError = (error) => {
        this.props.onFailure();
    }

    render() {
        let PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
        return (
            <div>
                {this.state.loading && <ApiLoader />}
                {this.state.reRender === false ? <PayPalButton
                    createOrder={(data, actions) => this.createOrder(data, actions)}
                    onApprove={(data, actions) => this.onApprove(data, actions)}
                    catchError={(error) => this.setError(error)}
                    onError={(error) => this.setError(error)}
                    onCancel={this.rerender}
                    style={{ label: "pay" }}
                /> : <ApiLoader />}
                <div className="bottom_buttons text-right">
                    <button type="button" onClick={this.rerender} className="yellow_btn">Reset Form</button>
                </div>
            </div>
        )
    }
}