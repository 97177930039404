import axios from "axios";

/*========================================================
 * function Name: requestApiClient
 * function Purpose: check api calling is valid
 * function Parameters: method, baseURL, url, data, headers
 * function ReturnType: data and status
 * function Description: requestApiClient method api calling using fetch
 *=====================================================*/

/**
 * @typedef {Object} ApiClientRequestConfig
 * @prop {string} [method]
 * @prop {string} url
 * @prop {string | FormData} [data]
 * @prop {{ [header: string]: string }} [headers]
 *
 * @typedef {Object} ApiClientResponse
 * @prop {*} data
 * @prop {number} status
 */

export default class ApiClient {
    /** @param {{ baseURL: string }} config */
    constructor({ baseURL }) {
        this.config = { baseURL };
    }

    /**
     * @param {ApiClientRequestConfig} config
     * @returns {Promise<ApiClientResponse>}
     */

    async requestApiClient(config) {
        const { method, baseURL, url, data, headers } = {
            ...this.config,
            ...config
        };

        const fetchUrl = `${baseURL}${url}`;

        // Axios  call

        let fetchResponse;
        if (method === "get" || method === "GET") {
            fetchResponse = await axios.get(fetchUrl, { headers: headers });
        } else if (method === "delete" || method === "DELETE") {
            fetchResponse = await axios.delete(fetchUrl, {
                headers: headers,
                data: data
            });
        } else if (method === "put" || method === "PUT") {
            fetchResponse = await axios.put(fetchUrl, data, {
                headers: headers
            });
        } else if (method === "post" || method === "POST") {
            fetchResponse = await axios.post(fetchUrl, data, {
                headers: headers
            });
        }
        return {
            data: fetchResponse.data,
            status: fetchResponse.status
        };
    }
}
