import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApiLoader from '../common/loader';
import { getRemovalInstruction } from "../redux/actions/violationActions";

class RemovalInstruction extends Component {
  
  componentDidMount(){
    this.props.getRemovalInstruction();
  }
  render() {
    return (
      <>
        {this.props.loading && <ApiLoader />}
        <section class="removalinstruction">
      	<div class="container">
      		<div class="row">
      			<div class="col-md-7">
      				<div class="remove-list-step">
      					<div class="boot-title">
      						<a href="#">Boot Release Instruction</a>
      					</div> 
      					
						  <p dangerouslySetInnerHTML={{__html: this.props.RemovalInstructionData?.boot_removal_instruction}}></p>
						{/* <ul>
      						<li>
      							<p>
      								<strong>Step 1 : </strong>
      								Scan QR code or go to the URL (website) located on the citation. 
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 2 : </strong>
      								Enter in your license plate and review the violation. 
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 3 : </strong>
      								Agree to the terms and make the payment. 
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 4 : </strong>
      								Use the onetime code received to unlock the lock box located at the booting station and retrieve the boot key.
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 5 : </strong>
      								Use the key to unlock the boot (make sure the lock pops up) Then pull the boot apart. 
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 6 : </strong>
      								Return the boot and key to the boot station, ensure the lockbox with the returned key is secure and the boot is placed back on the station and secure as well. 
      							</p>
      						</li>
      						<li>
      							<p>
      								<strong>Step 7 : </strong>
      								Take a picture of all items secured and send to parking@gpsvcs.com.   
      							</p>
      						</li>
      					</ul> */}
      					<div class="help-link-cover">Help Link :
      						{/* <a href="#"> https://youtu.be/HIFC_HO17nU </a> */}
							  <a href={this.props.RemovalInstructionData?.boot_removal_video_link} target="_blank"> {this.props.RemovalInstructionData?.boot_removal_video_link} </a>
							  
      					</div>
      				</div>
      			</div>
      			<div class="col-md-5">
      				<div class={this.props.RemovalInstructionData?.image ? "map-image-cover" : "map-image-none"} >
					{/* <div class="map-image-cover"> */}
						 <img src={this.props.RemovalInstructionData?.image} />
						  {/* <img src="https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-674010.jpg&fm=jpg" alt="" /> */}
						{/* <img src="https://media.istockphoto.com/photos/open-book-close-up-at-the-library-picture-id1302676874" /> */}
					</div>
					<div class={this.props.RemovalInstructionData?.boot_station_location_image ? "map-image-cover" : "map-image-none"} >
						 <img src={this.props.RemovalInstructionData?.boot_station_location_image} />
						 {/* {console.log("boot Location Image",this.props.RemovalInstructionData)} */}
					</div>
      			</div>
      		</div>
      	</div>
      </section>
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.violation.loading,
  RemovalInstructionData: state.violation.removalInstruction
});

const dispatchers = {
	getRemovalInstruction
};

export default connect(mapStateToProps, dispatchers)(RemovalInstruction);


    