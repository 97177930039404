import { Field, Form, Formik } from "formik";
import React from "react";
import FormInput from "../common/FormInput";
import * as Yup from "yup";
import { connect } from "react-redux";
import { postPaymentAuthorizeDotNet } from "../redux/actions/violationActions";
import { useDispatch } from "react-redux";

function AuthorizePayment({
  amount,

  handleSubmit,
  holdAmount,
}) {
  const dispatch = useDispatch();
  // const handleSubmit = async (values) => {
  //   console.log("values", values);
  //   const data = {
  //     violation_id: violationId,
  //     licensePlateNumber: licensePlateNumber,
  //     signature: signature,
  //     cardNumber: values.cardNumber,
  //     expiration_year: values.expirationYear,
  //     expiration_month: values.expirationMonth,
  //     cvv: values.cvv,
  //     amount: amount,
  //     total_amount: "",
  //     hold_amount: "",
  //     name_on_card: values.nameOnCard,
  //     email: emailAddress,
  //   };
  //   const res = await dispatch(postPaymentAuthorizeDotNet(data, violationId));
  //   if (res) {
  //     console.log("response", res);
  //   }
  // };
  return (
    <>
      <Formik
        validationSchema={Yup.object().shape({
          nameOnCard: Yup.string()
            .required("Card name is required")
            .min(2, "Card name must be at least 2 characters")
            .matches(/^[a-zA-Z ]+$/, "Must be only Alphabets")
            .max(50, "Card name must be at least 50 characters"),
          cardNumber: Yup.string("Must be number")
            .required("Card number is required")
            .min(16, "Card number must be at least 16 characters")
            .matches(/^[0-9]+$/, "Must be only digits or positive number")
            .max(16, "Card number must be at most 16 characters"),
          cvv: Yup.string()
            .min(3, "CVV must be at least 3 characters")
            .max(3, "CVV must be at most 3 characters")
            .matches(/^[0-9]+$/, "Must be only digits or positive number")
            .required("CVV is required"),
          expirationYear: Yup.string()
            .min(4, "Expiration year must be at least 4 characters")
            .max(4, "Expiration year must be at most 4 characters")
            .matches(/^[0-9]+$/, "Must be only digits or positive number")
            .required("Expiration year is required"),
          expirationMonth: Yup.string()
            .min(1, "Expiration month must be at least 1 characters")
            .max(2, "Expiration month must be at most 2 characters")
            .matches(
              /^(1[0-2]|[1-9])$/,
              "Must be only digits between 1 to 12 or positive number "
            )
            .required("Expiration month is required"),
        })}
        initialValues={{
          nameOnCard: "",
          cardNumber: "",
          expirationYear: "",
          expirationMonth: "",
          cvv: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          status,
        }) => (
          <React.Fragment>
            {/* <Form onSubmit={() => handleSubmit(values)}> */}
            {/* Name on Card */}
            <div>
              <label>Name On Card</label>
              <FormInput
                name="nameOnCard"
                placeholder="Name On Card"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nameOnCard}
                error={errors.nameOnCard}
                touched={touched.nameOnCard}
                className="form-control"
              />
            </div>

            {/* Card Number */}
            <div>
              <label>Card Number</label>
              <FormInput
                type="text"
                name="cardNumber"
                placeholder="Ex. 1234123412341234"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cardNumber}
                error={errors.cardNumber}
                touched={touched.cardNumber}
                className="form-control"
                min={16}
                max={16}
              />
            </div>

            {/* CVV */}
            <div>
              <label>CVV</label>
              <FormInput
                type="password"
                name="cvv"
                placeholder="Ex. 123"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cvv}
                error={errors.cvv}
                touched={touched.cvv}
                className="form-control"
                min={1}
                max={3}
              />
            </div>

            {/* Expiration Year */}
            <div>
              <label>Expiration Year</label>
              <FormInput
                type="text"
                name="expirationYear"
                placeholder="Ex. 2022"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.expirationYear}
                error={errors.expirationYear}
                touched={touched.expirationYear}
                className="form-control"
              />
            </div>

            {/* Expiration Month */}
            <div>
              <label>Expiration Month</label>
              <FormInput
                type="text"
                name="expirationMonth"
                placeholder="Ex. 3"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.expirationMonth}
                error={errors.expirationMonth}
                touched={touched.expirationMonth}
                className="form-control"
              />
            </div>

            <button type="submit" onClick={handleSubmit}>
              Pay ${Number(amount) + Number(holdAmount)}
            </button>
            {/* </Form> */}
          </React.Fragment>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  violationsData: state.violation.authorizeDotNet,
  loading: state.violation.loading,
});

export default connect(mapStateToProps)(AuthorizePayment);
