import _ from "lodash";
import {getAPIToken} from "../redux/actions/violationActions";

/*========================================================
 * function Name: createApiMiddleware
 * function Purpose: check api calling using apiMidddleWare
 * function Parameters: url, method, body, onLoadStart, onLoadEnd, onSuccess, onError
 * function ReturnType: data and error
 * function Description: createApiMiddleware method api calling using apiMidddleWare
 *=====================================================*/

/**
 * @param {import('./ApiClient').default} apiClient
 * @returns {import('redux').Middleware}
 */

export default function createApiMiddleware(apiClient) {
    return function apiMiddleware(middlewareAPI) {
        const { dispatch } = middlewareAPI;

        return next => async action => {
            next(action);

            if (action.type === API_REQUEST) {
                const {
                    url,
                    method,
                    body,
                    onLoadStart,
                    onLoadEnd,
                    onSuccess,
                    onError
                } = action.payload;

                var request = null;
                request = {
                    method: method,
                    url: url,
                    data: body,
                    headers: {
                        "Content-Type":
                            body && body.constructor === FormData
                                ? "multipart/form-data"
                                : "application/json"
                    }
                };
                try {
                    if (typeof onLoadStart === "function") {
                        dispatch(onLoadStart());
                    }
                    const response = await apiClient.requestApiClient(request);
                    if (typeof onLoadEnd === "function") {
                        dispatch(onLoadEnd());
                    }
                    if (response.data.Status) {
                        if (typeof onSuccess === "function") {
                            dispatch(onSuccess(response.data));
                        }
                    } else if (_.isObject(response.data)) {
                        if (typeof onSuccess === "function") {
                            dispatch(onSuccess(response.data));
                        }
                    } else if (response.data.length > 0) {
                        if (typeof onSuccess === "function") {
                            dispatch(onSuccess(response.data));
                        }
                    } else {
                        if (typeof onError === "function") {
                            dispatch(onError(response.data));
                        }
                    }
                } catch (error) {
                    if (error.response && (error.response.status === 401 || error.response.status === 403)){
                        next(getAPIToken());
                    }
                    dispatch(onError(error));
                }
            }
        };
    };
}

export const API_REQUEST = "api/request";

/**
 * @typedef {Object} ApiPayload
 * @property {string} [method]
 * @property {string} url
 * @property {*} [body]
 * @property {Function} [onLoadStart]
 * @property {Function} [onLoadEnd]
 * @property {Function} [onSuccess]
 * @property {Function} [onError]
 *
 * @param {ApiPayload} payload
 * @returns {{ type: 'api/request', payload: ApiPayload }}
 **/
export function apiRequest(payload) {
    return { type: API_REQUEST, payload };
}
