import HomeComponent from "./components/home";
import UnpaidViolation from "./components/unpaidViolation";
import PastViolations from "./components/pastViolations";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Link, Redirect, Route, Switch } from "react-router-dom";
import ViolationDetails from "./components/violationDetails";
import PaypalPayment from "./components/paypal";
import { ToastContainer } from "react-toastify";
import PaymentSuccess from "./components/paymentSuccess";
import PageNotFound from "./components/404";
import ContactUS from "./components/contactUs";
import RemovalInstruction from "./components/RemovalInstruction";
import { routes } from "./routings";
import { useEffect, useState } from "react";
import { REACT_APP_API_KEY, REACT_APP_API_URL } from "./common/config";
import axios from "axios";

function App() {
  const date = new Date();
  const [publicLogoData,setPublicLogoData] = useState();

  useEffect(() => {   
    axios.get(REACT_APP_API_URL + "web/logo-favicons", {
        headers: {
          'api-key': REACT_APP_API_KEY
        } 
      }).then((response) => {
      if(response?.status === 200) {
         // Remove existing favicon
         const existingLink = document.querySelector("link[rel*='icon']");
         if (existingLink) {
           existingLink.parentNode.removeChild(existingLink);
         }

        // Set dynamic favicon        
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = response?.data?.data?.parking?.fav_icon;
        document.head.appendChild(newLink);
        setPublicLogoData(response?.data?.data)
      }
      }) 
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <div className="App">
          {/* Header Starts */}
          <section className="header">
            <div className="top_head">
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <div className="logo">
                      {/* <a href="/"><h2>GPS</h2></a> */}
                      <a href={routes.home.path}>
                        <h2>{ publicLogoData?.parking?.logo_text  ?  publicLogoData?.parking?.logo_text  : ''}</h2>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="question_mark">
                      <a href={routes.contactUs.path}>
                        <h2>?</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Header Ends */}

          {/* Main Section Starts */}

          <Switch>
            <Route path={routes.home.path} exact component={HomeComponent} />

            <Route
              path={routes.unpaidViolation.path}
              component={UnpaidViolation}
            />

            <Route
              path={routes.pastViolations.path}
              component={PastViolations}
            />

            <Route
              path={routes.violationDetails.path}
              component={ViolationDetails}
            />

            {/* <Route path={routes.payPal.path} component={PaypalPayment} /> */}

            <Route path={routes.success.path} component={PaymentSuccess} />

            <Route path={routes.contactUs.path} component={ContactUS} />

            <Route
              path={routes.removalInstruction.path}
              component={RemovalInstruction}
            />

            <Route path={routes.pageNotFound.path} component={PageNotFound} />
            <Redirect to={routes.home.path} />
          </Switch>

          {/* Main Section Ends */}

          {/* Footer Starts */}
          <footer>
            <div className="footer_main">
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <p className="gpsvs">
                      © {date.getFullYear()} Gpsvcs - All Rights Reserved.
                    </p>
                  </div>
                  <div className="col-md-2">
                    <a href={routes.contactUs.path}>
                      <div className="need_help">
                        <p>Need Help ?</p>
                        <i className="fas fa-play"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* Footer Ends */}
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
