import * as types from "./types";
import * as URL from "./webApiList";
import { apiRequest } from "./index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_APP_API_URL } from "../../common/config";

export function incrementLoading() {
  return { type: types.CHANGE_LOADING, payload: true };
}

export function decrementLoading() {
  return { type: types.CHANGE_LOADING, payload: false };
}

export function setError(payload) {
  return { type: types.SET_ERROR, payload };
}

export function getViolatiosByPlateNumberSuccess(payload) {
  return { type: types.GET_ALL_VIOLATIONS_BY_PLATE_NUMBER_SUCCESS, payload };
}

export function getRemovalInstructionSuccess(payload) {
  return { type: types.GET_REMOVAL_INSTRUCTION_SUCCESS, payload };
}

export function paymentAuthorizeDotNetSuccess(payload) {
  return { type: types.POST_PAYMENT_AUTHORIZEDOTNET_SUCCESS, payload };
}

export function getFirmwareVersionSuccess(payload) {
  return { type: types.GET_FIRMWARE_SUCCESS, payload };
}

export function getAPITokenSuccess(payload) {
  localStorage.setItem("api_token", payload.token);
  return { type: types.GET_API_TOKEN_SUCCESS, payload };
}

export function getUnlockCodeByPaymentSuccess(payload) {
  return { type: types.GET_UNLOCK_CODE_BY_PAYMENT_SUCCESS, payload };
}

export function getPDFLinkSuccess(payload) {
  var link = document.createElement("a");
  link.href = payload.response.pdf;
  link.target = "_blank";
  link.download = "file.pdf";
  link.dispatchEvent(new MouseEvent("click"));
  return { type: types.GET_PAYMENT_RECEIPT_LINK_SUCCESS, payload };
}

export function submitContactUsSuccess(payload) {
  return { type: types.SUBMIT_CONTACT_US_SUCCESS, payload };
}

export function getViolationDetailsSuccess(payload) {
  console.log(payload);
  return { type: types.GET_VIOLATION_DETAILS_SUCCESS, payload };
}

export function getAPIToken() {
  return apiRequest({
    url: URL.GET_TOKEN,
    method: "GET",
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload) {
          dispatch(getAPITokenSuccess({ ...payload }));
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getViolatiosByPlateNumber(plateNumber) {
  const token = localStorage.getItem("api_token");
  return apiRequest({
    url: URL.GET_VIOLATION_DATA,
    method: "POST",
    body: {
      license_plate_number: plateNumber,
      token: token,
    },
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getViolatiosByPlateNumberSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getUnlockCodeByPayment(txnData) {
  return apiRequest({
    url: URL.GET_UNLOCK_CODE_BY_PAYMENT,
    method: "POST",
    body: txnData,
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getUnlockCodeByPaymentSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getPDFLink(id, license_plate_number) {
  return apiRequest({
    url: URL.GET_PAYMENT_RECEIPT_LINK,
    method: "POST",
    body: {
      license_plate_number: license_plate_number,
      id: id,
    },
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getPDFLinkSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function submitContactUs(email, name, message) {
  return apiRequest({
    url: URL.SUBMIT_CONTACT_US,
    method: "POST",
    body: {
      name: name,
      email: email,
      message: message,
    },
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(submitContactUsSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getViolationDetails(license_plate_number, id) {
  return apiRequest({
    url: URL.GET_VIOLATION_DETAILS,
    method: "POST",
    body: {
      license_plate_number: license_plate_number,
      id: id,
    },
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getViolationDetailsSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getRemovalInstruction() {
  const property_id = localStorage.getItem("propertyId");
  return apiRequest({
    url: URL.GET_REMOVAL_INSTRUCTION + "/" + property_id,
    method: "GET",
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getRemovalInstructionSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

export function getFirmwareVersion(violation_id) {
  const token = localStorage.getItem("api_token");
  return apiRequest({
    url: URL.GET_FIRMWARE,
    method: "POST",
    body: {
      token: token,
      violation_id: violation_id,
    },
    onLoadStart: incrementLoading,
    onLoadEnd: decrementLoading,
    onSuccess: (payload) => {
      return (dispatch) => {
        if (payload.success) {
          dispatch(getFirmwareVersionSuccess({ ...payload }));
        } else {
          toast.error(payload.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    },
    onError: (error) => setError(error),
  });
}

// export function postPaymentAuthorizeDotNet(data) {
//   const token = localStorage.getItem("api_token");
//   return apiRequest({
//     url: URL.POST_PAYMENT_AUTHORIZEDOTNET,
//     method: "POST",
//     body: {
//       token: token,
//       violation_id: data.violation_id,
//       licensePlateNumber: data.licensePlateNumber,
//       signature: data.signature,
//       cardNumber: data.cardNumber,
//       expiration_year: data.expiration_year,
//       expiration_month: data.expiration_month,
//       cvv: data.cvv,
//       amount: data.amount,
//       total_amount: "",
//       hold_amount: "",
//       name_on_card: data.name_on_card,
//       email: data.email,
//     },
//     onLoadStart: incrementLoading,
//     onLoadEnd: decrementLoading,
//     onSuccess: (payload) => {
//       console.log("in api call fun");
//       return (dispatch) => {
//         if (payload.success) {
//           dispatch(paymentAuthorizeDotNetSuccess({ ...payload }));
//         } else {
//           toast.error(payload.message, {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         }
//       };
//     },
//     onError: (error) => setError(error),
//   });
// }

export async function postPaymentAuthorizeDotNet(data) {
  const token = localStorage.getItem("api_token");
  return axios
    .post(REACT_APP_API_URL + URL.POST_PAYMENT_AUTHORIZEDOTNET, {
      token: token,
      violation_id: data.violation_id,
      licensePlateNumber: data.licensePlateNumber,
      signature: data.signature,
      cardNumber: data.cardNumber,
      expiration_year: data.expiration_year,
      expiration_month: data.expiration_month,
      cvv: data.cvv,
      amount: data.amount,
      total_amount: "",
      hold_amount: "",
      name_on_card: data.name_on_card,
      email: data.email,
    })
    .then((response) => {
      paymentAuthorizeDotNetSuccess(response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
