import React, { Component } from 'react';
import {routes} from "../routings";

export default class PaymentFail extends Component{

    sendBack = () =>{
        window.location.href = `${routes.home.path}`;
    }

    render() {
        return (
            <div>
                <section className="main_section wrong_part">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left_part">
                                    <h1 className="some">Something Went Wrong</h1>
                                    <p className="unsucces">Your payment was unsuccessful.</p>
                                    <h1 className="some exclamation">!</h1>
                                    <div className="bottom_buttons error_button">
                                        <button onClick={()=>this.sendBack()} className="yellow_btn">Try Again</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}