export const CHANGE_LOADING = "CHANGE_LOADING";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const GET_ALL_VIOLATIONS_BY_PLATE_NUMBER_SUCCESS =
  "GET_ALL_VIOLATIONS_BY_PLATE_NUMBER_SUCCESS";
export const GET_API_TOKEN_SUCCESS = "GET_API_TOKEN_SUCCESS";

export const GET_UNLOCK_CODE_BY_PAYMENT_SUCCESS =
  "GET_UNLOCK_CODE_BY_PAYMENT_SUCCESS";
export const GET_PAYMENT_RECEIPT_LINK_SUCCESS =
  "GET_PAYMENT_RECEIPT_LINK_SUCCESS";

export const SUBMIT_CONTACT_US_SUCCESS = "SUBMIT_CONTACT_US_SUCCESS";

export const GET_VIOLATION_DETAILS_SUCCESS = "GET_VIOLATION_DETAILS_SUCCESS";

export const GET_REMOVAL_INSTRUCTION_SUCCESS =
  "GET_REMOVAL_INSTRUCTION_SUCCESS";
export const GET_FIRMWARE_SUCCESS = "GET_FIRMWARE_SUCCESS";

export const POST_PAYMENT_AUTHORIZEDOTNET_SUCCESS =
  "POST_PAYMENT_AUTHORIZEDOTNET_SUCCESS";
