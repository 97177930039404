import React from "react";

const ApiLoader = () => {
    return (
        <React.Fragment>
            <div className="loading-overlay is-active">
                <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
        </React.Fragment>
    );
};

export default ApiLoader;
