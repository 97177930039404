import React, { Component } from 'react';
import {submitContactUs} from "../redux/actions/violationActions";
import { connect } from 'react-redux';
import ApiLoader from "../common/loader";

class ContactUS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            submitted: false,
            isEmailValid: true,
            showSuccess: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.successContact){
            return {
                name: "",
                email: "",
                message: "",
                submitted: false,
                isEmailValid: true,
                showSuccess: true
            }
        }
        return null;
    }

    submitForm=(event)=>{
        event.preventDefault();
        event.stopPropagation();
        this.setState({submitted: true});
        const {name, email, message} = this.state;
        let error = false;
        if(name === "" || email === "" || message === ""){
            error = true;
        }
        
        if(email !== ""){
            if(!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)){
                this.setState({isEmailValid: false});
                error = true;
            }
        }
        if(error){
            return;
        }
        this.props.submitContactUs(email, name, message);
    }

    render() {
        const {name, email, message, submitted, isEmailValid, showSuccess} = this.state;
        const {loading} = this.props;
        return (
            <div>
                {loading && <ApiLoader />}
                <section className="main_section wrong_part wrong_part_past">
                    <div className="container">
                        <div className="row">
                            <h1 className="desk_help">Help Desk</h1>
                            <div className="col-md-6">
                                <div className="help_main">
                                    <p className="faq">FAQ</p>
                                    <p><h4>If you need help with the code retrieval, location of the boot stand, operating a boot removal, or if you would like to dispute the citation please email the vehicle information and reason for dispute to <a href="mailto:parking@gpsvcs.com">parking@gpsvcs.com</a></h4></p>
                                    
                                    {/* <div className="wrapper center-block">
                                        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div className="panel panel-default">
                                                <div className="panel-heading active" role="tab" id="headingOne">
                                                    <h4 className="panel-title">
                                                        <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            How do I get code?
							                            </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                                    <div className="panel-body">
                                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
							                        </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id="headingTwo">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            What if I do not receive code on mobile?
							                            </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                    <div className="panel-body">
                                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
							                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="help_right">
                                    <p className="faq">Help</p>
                                    <a href="mailto:parking@gpsvcs.com"><i className="fal fa-envelope"></i>parking@gpsvcs.com</a>
                                    <a href="tel:+1 888-240-4993"><i className="fal fa-phone"></i>+1 888-240-4993</a>
                                    <p className="faq">Contact Us</p>
                                    {showSuccess && <h3 style={{color: "#73DF59"}}>We have received your request and will get back to you shortly.</h3>}
                                    <form onSubmit={this.submitForm} className="contactus">
                                        <input value={name} onChange={(event)=>this.setState({name:event.target.value})} type="text" name="name" placeholder="Name" />
                                        {submitted && name === "" && <p className="text-danger">Name is required.</p>}
                                        <input value={email} onChange={(event)=>this.setState({email:event.target.value})} type="text" name="email" placeholder="Email*" />
                                        {submitted && email === "" && <p className="text-danger">Email is required.</p>}
                                        {submitted && !isEmailValid && email !== "" && <p className="text-danger">Please enter a valid email address.</p>}
                                        <textarea value={message} onChange={(event)=>this.setState({message:event.target.value})} rows="4" cols="50" placeholder="Message..."></textarea>
                                        {submitted && message === "" && <p className="text-danger">Message is required.</p>}
                                        <div className="bottom_buttons">
                                            <button type="submit" className="yellow_btn">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.violation.loading,
    successContact: state.violation.successContact
});

const dispatchers = {
    submitContactUs
};

export default connect(mapStateToProps, dispatchers)(ContactUS);