import { combineReducers } from "redux";
import violationReducer from "./violationReducers";

const appReducer = combineReducers({
    violation: violationReducer
})

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;